import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import {
  Card,
  CardBody,
  Typography,
  Button,
  HelpButton
} from "../../../../atoms";
import locales from "../../../../locales";
import K from "../../../../constants";
import useShopperContext from "../../../../contexts/Shopper";

const useStyles = createUseStyles({
  help: {
    margin: "24px 0 0"
  },
  help__content: {
    display: "flex",
    flexDirection: "column",
    "& > :first-child": {
      marginBottom: "16px"
    }
  },
  help__content__button: {
    width: "100%",
    justifyContent: "flex-start"
  },
  help_request: {
    display: "flex",
    "& > button": {
      marginTop: "8px",
      marginBottom: "8px",
      justifyContent: "flex-start",
      "& > span": {
        display: "flex"
      }
    }
  }
});

const Help = ({
  metadata: { reference, contactHours, phone, phonePrefix },
  onClickButton
}) => {
  const isRenting = reference === "RENTING";
  const theme = useTheme();
  const classes = useStyles({ isRenting });
  const intl = useIntl();
  const { shopper } = useShopperContext();
  let title;
  let description;
  const phoneNumber = phone
    ? `${phonePrefix.replace(/[+]/, "")}${phone.replace(/\s/g, "")}`
    : null;

  switch (reference) {
    case "SUPPORT":
      title = intl.formatMessage(locales.order_help_support_title);
      description = intl.formatMessage(locales.order_help_support_description);
      break;
    case "RECOVERY":
      title = intl.formatMessage(locales.order_help_recovery_title);
      description = intl.formatMessage(locales.order_help_recovery_description);
      break;
    case "RENTING":
      title = intl.formatMessage(locales.order_help_renting_title);
      description = intl.formatMessage(locales.order_help_renting_description);
      break;
    default:
      break;
  }

  return (
    <Card className={classes.help}>
      <CardBody className={classes.help__content}>
        <Typography variant={K.typographicVariants.heading4}>
          {title}
        </Typography>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.secondary.default}
        >
          {description}
        </Typography>

        {isRenting ? (
          <Button
            variant="text"
            onClick={onClickButton}
            className={classes.help__content__button}
          >
            <HelpButton medium="renting" />
          </Button>
        ) : (
          <>
            <div className={classes.help_request}>
              <Button variant="text" onClick={onClickButton}>
                <HelpButton medium="call" phoneNumber={phone} />
              </Button>
              <Button
                variant="text"
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send?phone=${phoneNumber}`
                  )
                }
              >
                {shopper.realm === K.realm.sequra && (
                  <HelpButton medium="whatsapp" />
                )}
              </Button>
            </div>
            <Typography
              variant={K.typographicVariants.body3}
              color={theme.palette.secondary.default}
            >
              {contactHours}
            </Typography>
          </>
        )}
      </CardBody>
    </Card>
  );
};

Help.propTypes = {
  metadata: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    contactHours: PropTypes.string,
    phone: PropTypes.string,
    phonePrefix: PropTypes.string,
    email: PropTypes.string,
    url: PropTypes.string
  }).isRequired,
  onClickButton: PropTypes.func
};
Help.defaultProps = {
  onClickButton: () => {}
};

export default Help;
