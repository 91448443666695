import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  from
} from "@apollo/client";
import store from "store";
import possibleTypes from "../graphql/possibleTypes/data.json";
import offsetPagination from "./pagination";

const httpLink = createHttpLink({
  uri: window.env.api,
  credentials: "include"
});

const reviewersUriMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(() => ({
    uri: store.get("api") || window.env.api
  }));

  return forward(operation);
});

const client = new ApolloClient({
  link: from([reviewersUriMiddleware, httpLink]),
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          orders: offsetPagination(["filters"])
        }
      },
      Shopper: {
        fields: {
          orders: {
            // Make cache independent based on the filter_by
            keyArgs: ["filter_by"],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing = [], incoming) {
              const knownIds = {};
              const merged = [...existing];

              existing.forEach(element => {
                knownIds[element.__ref] = true;
              });

              incoming.forEach(element => {
                if (!knownIds[element.__ref]) {
                  merged.push(element);
                }
              });

              return merged;
            },
            read(existing) {
              return existing;
            }
          }
        }
      }
    }
  })
});

export default client;
