import gql from "graphql-tag";
import {
  ORDER_INFO_FRAGMENT,
  INVOICES_FRAGMENT,
  MERCHANT_FRAGMENT,
  UNSHIPPED_CART_FRAGMENT,
  SHIPPED_CART_FRAGMENT,
  PAYMENT_PLAN_FRAGMENT,
  RENTING_PLAN_FRAGMENT,
  CARD_REFERENCE_FRAGMENT,
  CONVERSION_PLANS_FRAGMENT,
  REFUND_PROCESS_FRAGMENT,
  PHONES_FRAGMENT,
  EMAILS_FRAGMENT,
  ADDRESSES_FRAGMENT,
  CARD_REFERENCES_FRAGMENT,
  SHOPPER_INFO_FRAGMENT,
  SHOPPER_SELECTOR_INLINE_FRAGMENT,
  SESSION_AUTHORIZATION_INLINE_FRAGMENT,
  SHORTENED_LINK_INLINE_FRAGMENT,
  CARD_REFERENCE_INLINE_FRAGMENT,
  CREATE_REFERRAL_GUEST_USER_INLINE_FRAGMENT,
  SHARE_REFERRAL_PROGRAM_INLINE_FRAGMENT,
  MERCHANTS_PROMOTIONS_FRAGMENT,
  GENERATE_CONVERSION_INLINE_FRAGMENT
} from "../fragments";

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
    }
  }
`;

export const REMOVE_CARD_REFERENCE = gql`
  mutation RemoveCardReference($cardReferenceId: ID!) {
    removeCardReference(cardReferenceId: $cardReferenceId) {
      ${CARD_REFERENCE_INLINE_FRAGMENT}
    }
  }
`;

export const SET_DEFAULT_CARD_REFERENCE = gql`
  mutation SetDefaultCardReference($cardReferenceId: ID!) {
    setDefaultCardReference(cardReferenceId: $cardReferenceId) {
      ${CARD_REFERENCE_INLINE_FRAGMENT}
    }
  }
`;

export const TOGGLE_AUTO_PAYMENT = gql`
  mutation ToggleAutoPayment {
    toggleAutoPayment {
      ...${SHOPPER_INFO_FRAGMENT.name}
      ...${EMAILS_FRAGMENT.name}
      ...${PHONES_FRAGMENT.name}
      ...${ADDRESSES_FRAGMENT.name}
      ...${CARD_REFERENCES_FRAGMENT.name}
    }
  }
  ${SHOPPER_INFO_FRAGMENT.entry}
  ${EMAILS_FRAGMENT.entry}
  ${PHONES_FRAGMENT.entry}
  ${ADDRESSES_FRAGMENT.entry}
  ${CARD_REFERENCES_FRAGMENT.entry}
`;

export const TOGGLE_ORDER_AUTO_PAYMENT = gql`
  mutation ToggleOrderAutoPayment($orderId: ID!) {
    toggleOrderAutoPayment(orderId: $orderId) {
      ...${ORDER_INFO_FRAGMENT.name}
      ...${MERCHANT_FRAGMENT.name}
      ...${UNSHIPPED_CART_FRAGMENT.name}
      ...${SHIPPED_CART_FRAGMENT.name}
      ...${INVOICES_FRAGMENT.name}
      ...${PAYMENT_PLAN_FRAGMENT.name}
      ...${RENTING_PLAN_FRAGMENT.name}
      ...${CARD_REFERENCE_FRAGMENT.name}
      ...${CONVERSION_PLANS_FRAGMENT.name}
      ...${REFUND_PROCESS_FRAGMENT.name}
    }
  }
  ${ORDER_INFO_FRAGMENT.entry}
  ${MERCHANT_FRAGMENT.entry}
  ${UNSHIPPED_CART_FRAGMENT.entry}
  ${SHIPPED_CART_FRAGMENT.entry}
  ${INVOICES_FRAGMENT.entry}
  ${PAYMENT_PLAN_FRAGMENT.entry}
  ${RENTING_PLAN_FRAGMENT.entry}
  ${CARD_REFERENCE_FRAGMENT.entry}
  ${CONVERSION_PLANS_FRAGMENT.entry}
  ${REFUND_PROCESS_FRAGMENT.entry}
`;

export const CREATE_SELECTOR = gql`
  mutation CreateSelector($emailOrPhone: String!) {
    createSelector(emailOrPhone: $emailOrPhone) {
      ${SHOPPER_SELECTOR_INLINE_FRAGMENT}
    }
  }
`;

export const VALIDATE_SELECTOR = gql`
  mutation ValidateSelector($selectorId: ID!, $selectorType: Selector!) {
    validateSelector(selectorId: $selectorId, selectorType: $selectorType) {
      ${SHOPPER_SELECTOR_INLINE_FRAGMENT}
    }
  }
`;

export const START_SELECTOR_VALIDATION = gql`
  mutation StartSelectorValidation($selectorId: ID!, $selectorType: Selector!) {
    startSelectorValidation(selectorId: $selectorId, selectorType: $selectorType) {
      ${SHOPPER_SELECTOR_INLINE_FRAGMENT}
    }
  }
`;

export const ASSIGN_CARD = gql`
  mutation AssignCard($orderId: ID!, $cardReferenceId: ID!) {
    assignCard(orderId: $orderId, cardReferenceId: $cardReferenceId) {
      __typename
      ... on Successful {
        order {
          ...${ORDER_INFO_FRAGMENT.name}
          ...${MERCHANT_FRAGMENT.name}
          ...${UNSHIPPED_CART_FRAGMENT.name}
          ...${SHIPPED_CART_FRAGMENT.name}
          ...${INVOICES_FRAGMENT.name}
          ...${PAYMENT_PLAN_FRAGMENT.name}
          ...${RENTING_PLAN_FRAGMENT.name}
          ...${CARD_REFERENCE_FRAGMENT.name}
          ...${CONVERSION_PLANS_FRAGMENT.name}
          ...${REFUND_PROCESS_FRAGMENT.name}
        }
      }
      ... on Failed {
        order {
          ...${ORDER_INFO_FRAGMENT.name}
          ...${MERCHANT_FRAGMENT.name}
          ...${UNSHIPPED_CART_FRAGMENT.name}
          ...${SHIPPED_CART_FRAGMENT.name}
          ...${INVOICES_FRAGMENT.name}
          ...${PAYMENT_PLAN_FRAGMENT.name}
          ...${RENTING_PLAN_FRAGMENT.name}
          ...${CARD_REFERENCE_FRAGMENT.name}
          ...${CONVERSION_PLANS_FRAGMENT.name}
          ...${REFUND_PROCESS_FRAGMENT.name}
        }
      }
    }
  }
  ${ORDER_INFO_FRAGMENT.entry}
  ${MERCHANT_FRAGMENT.entry}
  ${UNSHIPPED_CART_FRAGMENT.entry}
  ${SHIPPED_CART_FRAGMENT.entry}
  ${INVOICES_FRAGMENT.entry}
  ${PAYMENT_PLAN_FRAGMENT.entry}
  ${RENTING_PLAN_FRAGMENT.entry}
  ${CARD_REFERENCE_FRAGMENT.entry}
  ${CONVERSION_PLANS_FRAGMENT.entry}
  ${REFUND_PROCESS_FRAGMENT.entry}
`;

export const GENERATE_TOKENIZATION_PAYMENT_GATEWAY_REQUEST = gql`
  mutation GenerateTokenizationPaymentGatewayRequest($cardReferenceId: ID) {
    generateTokenizationPaymentGatewayRequest(
      cardReferenceId: $cardReferenceId
    ) {
      iframeUrl
    }
  }
`;

export const GENERATE_PAYMENT_PLAN_PAYMENT_GATEWAY_REQUEST = gql`
  mutation GeneratePaymentPlanPaymentGatewayRequest(
    $orderId: ID!
    $cardReferenceId: ID
    $cardFormLayoutVersion: CardFormLayoutVersion
    $amountCents: Int
  ) {
    generatePaymentPlanPaymentGatewayRequest(
      orderId: $orderId
      cardReferenceId: $cardReferenceId
      cardFormLayoutVersion: $cardFormLayoutVersion
      amountCents: $amountCents
    ) {
      iframeUrl
    }
  }
`;

export const GENERATE_PAYMENT_PLAN_TOKENIZATION_GATEWAY_REQUEST = gql`
  mutation GeneratePaymentPlanTokenizationGatewayRequest($orderId: ID!) {
    generatePaymentPlanTokenizationGatewayRequest(orderId: $orderId) {
      iframeUrl
    }
  }
`;

export const GENERATE_INVOICES_PAYMENT_GATEWAY_REQUEST = gql`
  mutation GenerateInvoicesPaymentGatewayRequest(
    $orderId: ID!
    $invoiceNumbers: [String!]!
    $cardReferenceId: ID
    $amountCents: Int
  ) {
    generateInvoicesPaymentGatewayRequest(
      orderId: $orderId
      invoiceNumbers: $invoiceNumbers
      cardReferenceId: $cardReferenceId
      amountCents: $amountCents
    ) {
      iframeUrl
    }
  }
`;

export const GENERATE_CONVERSION_PAYMENT_GATEWAY_REQUEST = gql`
  mutation GenerateConversionPaymentGatewayRequest(
    $orderId: ID!
    $instalmentCount: Int!
    $cardReferenceId: ID
  ) {
    generateConversionPaymentGatewayRequest(
      orderId: $orderId
      instalmentCount: $instalmentCount
      cardReferenceId: $cardReferenceId
    ) {
      iframeUrl
    }
  }
`;

export const GENERATE_CONVERSION_REQUEST = gql`
  mutation GenerateConversionRequest($orderId: ID!, $instalmentCount: Int!) {
    generateConversionRequest(
      orderId: $orderId
      instalmentCount: $instalmentCount
    ) {
      ${GENERATE_CONVERSION_INLINE_FRAGMENT}
    }
  }
`;

export const RETURN_REQUEST = gql`
  mutation returnRequest($orderId: ID!, $invoiceNumbers: [String!]!) {
    returnRequest(orderId: $orderId, invoiceNumbers: $invoiceNumbers) {
      ...${ORDER_INFO_FRAGMENT.name}
      ...${MERCHANT_FRAGMENT.name}
      ...${UNSHIPPED_CART_FRAGMENT.name}
      ...${SHIPPED_CART_FRAGMENT.name}
      ...${INVOICES_FRAGMENT.name}
      ...${PAYMENT_PLAN_FRAGMENT.name}
      ...${RENTING_PLAN_FRAGMENT.name}
      ...${CARD_REFERENCE_FRAGMENT.name}
      ...${CONVERSION_PLANS_FRAGMENT.name}
      ...${REFUND_PROCESS_FRAGMENT.name}
    }
  }
  ${ORDER_INFO_FRAGMENT.entry}
  ${MERCHANT_FRAGMENT.entry}
  ${UNSHIPPED_CART_FRAGMENT.entry}
  ${SHIPPED_CART_FRAGMENT.entry}
  ${INVOICES_FRAGMENT.entry}
  ${PAYMENT_PLAN_FRAGMENT.entry}
  ${RENTING_PLAN_FRAGMENT.entry}
  ${CARD_REFERENCE_FRAGMENT.entry}
  ${CONVERSION_PLANS_FRAGMENT.entry}
  ${REFUND_PROCESS_FRAGMENT.entry}
`;

export const ADD_BANK_ACCOUNT_TO_REFUND_PROCESS = gql`
  mutation AddBankAccountToRefundProcess($refundProcessId: ID!, $iban: String!, $orderId: ID!) {
    addBankAccountToRefundProcess(refundProcessId: $refundProcessId, iban: $iban, orderId: $orderId) {
      ...${ORDER_INFO_FRAGMENT.name}
      ...${MERCHANT_FRAGMENT.name}
      ...${UNSHIPPED_CART_FRAGMENT.name}
      ...${SHIPPED_CART_FRAGMENT.name}
      ...${INVOICES_FRAGMENT.name}
      ...${PAYMENT_PLAN_FRAGMENT.name}
      ...${RENTING_PLAN_FRAGMENT.name}
      ...${CARD_REFERENCE_FRAGMENT.name}
      ...${CONVERSION_PLANS_FRAGMENT.name}
      ...${REFUND_PROCESS_FRAGMENT.name}
    }
  }
  ${ORDER_INFO_FRAGMENT.entry}
  ${MERCHANT_FRAGMENT.entry}
  ${UNSHIPPED_CART_FRAGMENT.entry}
  ${SHIPPED_CART_FRAGMENT.entry}
  ${INVOICES_FRAGMENT.entry}
  ${PAYMENT_PLAN_FRAGMENT.entry}
  ${RENTING_PLAN_FRAGMENT.entry}
  ${CARD_REFERENCE_FRAGMENT.entry}
  ${CONVERSION_PLANS_FRAGMENT.entry}
  ${REFUND_PROCESS_FRAGMENT.entry}
`;

export const RENDER_SERVICE = gql`
  mutation RenderService($orderId: ID!) {
    renderService(orderId: $orderId) {
      ...${ORDER_INFO_FRAGMENT.name}
      ...${MERCHANT_FRAGMENT.name}
      ...${UNSHIPPED_CART_FRAGMENT.name}
      ...${SHIPPED_CART_FRAGMENT.name}
      ...${INVOICES_FRAGMENT.name}
      ...${PAYMENT_PLAN_FRAGMENT.name}
      ...${RENTING_PLAN_FRAGMENT.name}
      ...${CARD_REFERENCE_FRAGMENT.name}
      ...${CONVERSION_PLANS_FRAGMENT.name}
      ...${REFUND_PROCESS_FRAGMENT.name}
    }
  }
  ${ORDER_INFO_FRAGMENT.entry}
  ${MERCHANT_FRAGMENT.entry}
  ${UNSHIPPED_CART_FRAGMENT.entry}
  ${SHIPPED_CART_FRAGMENT.entry}
  ${INVOICES_FRAGMENT.entry}
  ${PAYMENT_PLAN_FRAGMENT.entry}
  ${RENTING_PLAN_FRAGMENT.entry}
  ${CARD_REFERENCE_FRAGMENT.entry}
  ${CONVERSION_PLANS_FRAGMENT.entry}
  ${REFUND_PROCESS_FRAGMENT.entry}
`;

export const PROCESS_SHORTENED_LINK = gql`
  mutation ProcessShortenedLink($code: String!) {
    processShortenedLink(code: $code){
      __typename
      ... on Authorized {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
        shortenedLinkAction {
          ${SHORTENED_LINK_INLINE_FRAGMENT}
        }
      }
      ... on Expired {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
      }
      ... on Invalid {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
      }
    }
  }
`;

export const CREATE_OTP = gql`
  mutation CreateOtp(
    $emailOrPhone: String!
    $nin: String!
    $keepSessionAuthorized: Boolean
  ) {
    createOtp(
      emailOrPhone: $emailOrPhone
      nin: $nin
      keepSessionAuthorized: $keepSessionAuthorized
    ) {
      __typename
      ... on EncryptedSelectors {
        otpHash
        selectorType
      }
      ... on InvalidArguments {
        keys
      }
      ... on InvalidUser {
        key
      }
      ... on LockedUser {
        key
      }
    }
  }
`;

export const CREATE_NEW_SESSION = gql`
  mutation NewSession($sessionIdHash: String!, $otp: String) {
    newSession(sessionIdHash: $sessionIdHash, otp: $otp) {
      __typename
      ... on Authorized {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
        shortenedLinkAction {
          ${SHORTENED_LINK_INLINE_FRAGMENT}
        }
      }
      ... on InvalidOtp {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
      }
    }
  }
`;

export const EVENT = gql`
  mutation Event(
    $type: Event!
    $occurredAt: ISO8601DateTime!
    $reference: String!
  ) {
    event(type: $type, occurredAt: $occurredAt, reference: $reference) {
      __typename
    }
  }
`;

export const SET_SHOPPER_ALLOWANCE_FOR_MERCHANTS_PROMOTIONS = gql`
  mutation SetShopperAllowanceForMerchantsPromotions($value: Boolean!) {
    setShopperAllowanceForMerchantsPromotions(value: $value) {
      ...${SHOPPER_INFO_FRAGMENT.name}
      ...${MERCHANTS_PROMOTIONS_FRAGMENT.name}
    }
  }
  ${SHOPPER_INFO_FRAGMENT.entry}
  ${MERCHANTS_PROMOTIONS_FRAGMENT.entry}
`;

export const REMOVE_SELECTOR = gql`
  mutation RemoveSelector($selectorId: ID!, $selectorType: Selector!) {
    removeSelector(selectorId: $selectorId, selectorType: $selectorType) {
      ${SHOPPER_SELECTOR_INLINE_FRAGMENT}
    }
  }
`;

export const CREATE_REFERRAL_GUEST_USER = gql`
  mutation CreateReferralGuestUser(
    $name: String!
    $email: String!
    $phone: String!
    $nin: String!
    $termsAndConditionsAccepted: Boolean!
  ) {
    createReferralGuestUser(
      name: $name
      email: $email
      phone: $phone
      nin: $nin
      termsAndConditionsAccepted: $termsAndConditionsAccepted
    ) {
      ${CREATE_REFERRAL_GUEST_USER_INLINE_FRAGMENT}
    }
  }
`;

export const VALIDATE_REFERRAL_GUEST_USER_OTP = gql`
  mutation ValidateReferralGuestUserOtp(
    $sessionIdHash: String!
    $otp: String!
  ) {
    validateReferralGuestUserOtp(
      sessionIdHash: $sessionIdHash
      otp: $otp
    ) {
      __typename
      ... on Authorized {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
      }
      ... on InvalidOtp {
        sessionAuthorization {
          ${SESSION_AUTHORIZATION_INLINE_FRAGMENT}
        }
      }
    }
  }
`;

export const SHARE_REFERRAL_PROGRAM = gql`
  mutation ShareReferralProgram {
    shareReferralProgram {
      ${SHARE_REFERRAL_PROGRAM_INLINE_FRAGMENT}
    }
  }
`;
