import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { useMedia } from "react-use";
import { NextModal, Typography } from "../../../../atoms";
import { useOrder } from "../../../../hooks";
import useTrackingContext from "../../../../contexts/Tracking";
import useNotificationContext from "../../../../contexts/Notification";
import K from "../../../../constants";
import locales from "../../../../locales";
import AmortizationModalDescription from "../../components/AmortizationModalDescription";
import ReadOnlySessionMufasaIframeMessage from "../../../../molecules/ReadOnlySessionMufasaIframeMessage";

import {
  getMufasaActionFromEvent,
  Mufasa3DSIframe,
  MufasaResponseMessages
} from "../../../../molecules/Mufasa3DS";
import { ScaleLoader } from "../../../../atoms/Loaders";
import useMufasaReducer, {
  MufasaActions
} from "../../../../hooks/reducers/useMufasaReducer";

const useStyles = createUseStyles({
  select_card_modal__title: {
    marginBottom: "32px"
  },
  select_card_modal__link: {
    appearance: "none",
    border: 0,
    padding: 0,
    margin: 0,
    position: "absolute",
    top: "calc(48px + 8px)",
    backgroundColor: "transparent",
    "@media(min-width: 768px)": {
      position: "static",
      top: "calc(48px + 32px + 8px)"
    }
  },
  mufasa: {
    display: "flex",
    alignItems: ({ error, loading }) => (error || loading ? "center" : "unset"),
    justifyContent: ({ error, loading }) =>
      error || loading ? "center" : "unset",
    position: "relative",
    margin: ({ loading }) => (loading ? "40px 0" : "24px 0 0 0"),
    minHeight: "auto",
    "& > iframe": {
      flex: 1
    }
  }
});

const AmortizationMufasaModal = ({ modalTitle }) => {
  const order = useOrder();
  const location = useLocation();
  const intl = useIntl();

  const theme = useTheme();
  const history = useHistory();
  const isWideScreen = useMedia("(min-width: 768px)");
  const { setSnackbarLayoutProps } = useNotificationContext();
  const { track, eventNames, buildOrderTrackingPayload } = useTrackingContext();
  const query = queryString.parse(location.search, { arrayFormat: "comma" });

  const iframeRef = React.useRef(null);

  const [{ loading, error, height }, dispatch] = useMufasaReducer();

  const classes = useStyles({ loading, error });

  const iframeUrl = order.amortization.cardPayment.url;

  const hasBankAccounts = order?.bankTransferPayment?.bankAccounts.length > 0;

  React.useEffect(() => {
    const { current } = iframeRef;
    if (current) current.style.height = `${height}px`;
  }, [height]);

  const mufasaEventHandler = event => {
    const action = getMufasaActionFromEvent(event);
    switch (action.mufasaResponseMessage) {
      case MufasaResponseMessages.mufasa_resized:
        dispatch({
          type: MufasaActions.resize,
          payload: { height: action.payload.height }
        });
        break;
      case MufasaResponseMessages.payment_successful: {
        dispatch({
          type: MufasaActions.loaded
        });
        const eventProps = buildOrderTrackingPayload(order, true, false);
        const success = queryString.stringifyUrl({
          url: location.pathname,
          query: {
            process: K.process.amortization,
            step: K.steps.success
          }
        });
        track(eventNames.amortization.success, eventProps);
        history.replace(success);
        break;
      }
      case MufasaResponseMessages.payment_failed: {
        dispatch({
          type: MufasaActions.error
        });
        const eventProps = buildOrderTrackingPayload(order, true, false);
        track(eventNames.amortization.fail, eventProps);
        setSnackbarLayoutProps({
          open: true,
          variant: "error",
          label: intl.formatMessage(locales.ups_something_went_wrong)
        });
        break;
      }
      case MufasaResponseMessages.mufasa_submitted:
        dispatch({
          type: MufasaActions.submit
        });
        break;
      default:
        break;
    }
  };

  return (
    <NextModal
      open
      title={isWideScreen ? intl.formatMessage(locales.pay_with_card) : ""}
      withDescription
      contentDescription={
        <AmortizationModalDescription modalTitle={modalTitle} />
      }
      hideBack={query.step === K.steps.summary}
    >
      {isWideScreen ? null : (
        <Typography
          variant={K.typographicVariants.heading2}
          color={theme.palette.primary.default}
          className={classes.select_card_modal__title}
        >
          {intl.formatMessage(locales.pay_with_card)}
        </Typography>
      )}
      {hasBankAccounts && (
        <button
          className={classes.select_card_modal__link}
          type="button"
          onClick={() => {
            const search = queryString.stringify(
              {
                ...query,
                step: K.steps.selectBankAccount
              },
              { arrayFormat: "comma" }
            );
            history.push(`${location.pathname}?${search}`);
          }}
        >
          <Typography variant={K.typographicVariants.linkS}>
            {intl.formatMessage(locales.by_wire_transfer)}
          </Typography>
        </button>
      )}

      <div className={classes.mufasa}>
        {loading && <ScaleLoader />}
        <ReadOnlySessionMufasaIframeMessage />
        {iframeUrl && (
          <Mufasa3DSIframe
            iframeRef={iframeRef}
            mufasaUrl={iframeUrl}
            handleIframeEvent={mufasaEventHandler}
            handleTrackEvent={track}
          />
        )}
      </div>
    </NextModal>
  );
};

AmortizationMufasaModal.propTypes = {
  modalTitle: PropTypes.shape({
    id: PropTypes.string,
    defaultMessages: PropTypes.string
  }).isRequired
};

export default AmortizationMufasaModal;
