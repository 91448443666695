import React, { useEffect } from "react";
import PropTypes from "prop-types";
import store from "store";
import { createUseStyles } from "react-jss";
import {
  Redirect,
  useLocation,
  matchPath,
  Switch,
  Route
} from "react-router-dom";
import { useMount } from "react-use";
import useSessionAuthenticationContext from "./contexts/SessionAuthentication";
import useShopper from "./contexts/Shopper";
import useCookiesConsent from "./contexts/CookiesConsent";
import useTracking from "./contexts/Tracking";
import routes, {
  DASHBOARD_PATH,
  LOGIN_PATH,
  ORDERS_PATH,
  VALIDATION_PATH,
  VERIFICATION_PATH,
  HELP_PATH,
  PROFILE_PATH,
  ORDER_PATH,
  TOKEN_PATH,
  SIGNUP_PATH,
  REFERRAL_PROGRAM_PATH
} from "./routes";

import { useIdentifyUsers } from "./hooks";

import { NoMatchPage } from "./components";
import { BarLoader } from "./atoms/Loaders";
import {
  Dashboard,
  Login,
  Validation,
  Orders,
  Order,
  Help,
  Profile,
  SelectorValidation,
  Token,
  SignUp,
  ReferralProgram
} from "./pages";
import { ShopperContext } from "./contexts";

import { CookiesBanner, Cookies } from "./molecules";
import { CookiesConfigurationModal, DataProtectionModal } from "./organisms";
import useProfile from "./pages/Profile/hooks/useProfile";
import K from "./constants";

const useStyles = createUseStyles(theme => ({
  "@global": {
    body: {
      fontFamily: theme.fontFamily,
      overflow: "auto",
      backgroundColor: theme.palette.background.default,
      "-webkit-font-smoothing": "antialiased"
    }
  }
}));

function App({ children }) {
  const {
    init: shopperInit,
    fetching,
    shopper,
    error: shopperError
  } = useShopper();

  const {
    basicInfo: { realm, merchantsPromotions },
    onChangeDataProtection,
    hasMerchantsPromotionsEnabled
  } = useProfile();

  const { pathname } = useLocation();
  const {
    isPartialSession,
    currentShopperUserId
  } = useSessionAuthenticationContext();

  const { showCookiesConfiguration } = useCookiesConsent();

  const isDashboard = matchPath(pathname, {
    path: DASHBOARD_PATH,
    exact: true
  });

  if (currentShopperUserId) {
    useIdentifyUsers(currentShopperUserId, { user_id: currentShopperUserId });
  }

  if (shopperError) return <NoMatchPage />;

  if (!shopperInit || fetching) return <BarLoader />;

  if (isDashboard && isPartialSession) {
    return <Redirect to={ORDERS_PATH} />;
  }

  if (store.get("locale") !== shopper.locale) {
    store.set("locale", shopper.locale);
    window.location.reload();
    return null;
  }

  const showDataProtectionModal =
    merchantsPromotions?.promotionsAllowed === "PENDING";

  return (
    <ShopperContext.Provider value={shopper}>
      {realm === "svea" &&
      showDataProtectionModal &&
      !showCookiesConfiguration ? (
        <DataProtectionModal
          hasMerchantsPromotionsEnabled={hasMerchantsPromotionsEnabled}
          onChangeDataProtection={onChangeDataProtection}
        />
      ) : null}
      {children}
    </ShopperContext.Provider>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired
};

App.defaultProps = {};

function AuthorizedAppRouter() {
  useStyles();
  const { pathname } = useLocation();
  const {
    init,
    isAuthorizing,
    isAuthorized,
    isFullSession,
    isReferralPartialSession,
    fetchSession,
    error,
    logout
  } = useSessionAuthenticationContext();

  const { initTrackers } = useTracking();

  const { shopper } = useShopper();

  const isPrivateRoute = routes
    .filter(route => route.private)
    .some(route => matchPath(pathname, route));

  const isLoginUrl = matchPath(pathname, { path: LOGIN_PATH, exact: true });
  const isSignUpUrl = matchPath(pathname, { path: SIGNUP_PATH, exact: true });
  const isValidationUrl = matchPath(pathname, {
    path: VALIDATION_PATH,
    exact: true
  });
  const isReferralProgramUrl = matchPath(pathname, {
    path: REFERRAL_PROGRAM_PATH,
    exact: true
  });

  const isShopperEnabledToBeHost = shopper?.referralProgram?.enabledToBeHost;

  useMount(() => {
    if (!isAuthorized) {
      fetchSession();
    }
  });

  useEffect(() => {
    if (isReferralPartialSession) {
      initTrackers();
    }
  }, [isReferralPartialSession]);

  if (error) return <NoMatchPage />;

  if (isReferralPartialSession && !isSignUpUrl) {
    logout();
  }
  if (!init || isAuthorizing) return <BarLoader />;

  if (isFullSession && isLoginUrl) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  if (isFullSession && isSignUpUrl) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  if (isFullSession && isValidationUrl) {
    return <Redirect to={DASHBOARD_PATH} />;
  }

  if (!isAuthorized && isPrivateRoute) {
    return <Redirect to={LOGIN_PATH} />;
  }

  if (shopper && !isShopperEnabledToBeHost && isReferralProgramUrl) {
    return <NoMatchPage />;
  }

  return (
    <Switch>
      <Route exact path={LOGIN_PATH} component={Login} />
      <Route exact path={VALIDATION_PATH} component={Validation} />
      <Route exact path={SIGNUP_PATH} component={SignUp} />
      <App>
        <Switch>
          <Route exact path={DASHBOARD_PATH} component={Dashboard} />
          <Route exact path={ORDERS_PATH} component={Orders} />
          <Route path={ORDER_PATH} component={Order} />
          <Route exact path={HELP_PATH} component={Help} />
          <Route exact path={PROFILE_PATH} component={Profile} />
          <Route
            exact
            path={REFERRAL_PROGRAM_PATH}
            component={ReferralProgram}
          />
          <Route
            exact
            path={VERIFICATION_PATH}
            component={SelectorValidation}
          />
          <Route component={NoMatchPage} />
        </Switch>
      </App>
    </Switch>
  );
}

function AppRouter() {
  const {
    setCookiesConsent,
    cookiesAllowed,
    setCookiesAllowed,
    cookiesConsentAnswered,
    setShowCookiesConfiguration,
    showCookiesConfiguration
  } = useCookiesConsent();
  const { shopper, fetching, init: shopperInit } = useShopper();
  const { initTrackers } = useTracking();

  const onAcceptCookies = () => {
    setCookiesConsent(true);
  };

  const openCookiesConfigurationModal = () => {
    setShowCookiesConfiguration(true);
  };

  useEffect(() => {
    if (!shopperInit || fetching) return;
    if (shopper?.realm === K.realm.sequra) setCookiesAllowed(true);
  }, [shopperInit, fetching, shopper]);

  useEffect(() => {
    if (cookiesAllowed) initTrackers();
  }, [cookiesAllowed]);

  const locale = K.supportedLocales[shopper?.locale || store.get("locale")];
  const language = K.supportedLanguagesFromLocale[locale];

  return (
    <>
      <Switch>
        <Route exact path={TOKEN_PATH} component={Token} />
        <AuthorizedAppRouter />
      </Switch>

      {locale !== K.supportedLocales.es || shopper?.realm === K.realm.svea ? (
        <>
          {cookiesConsentAnswered || showCookiesConfiguration ? null : (
            <CookiesBanner
              onAccept={onAcceptCookies}
              onConfiguration={openCookiesConfigurationModal}
              cookiesPolicyLink={`${K.links.cookies_policy}?locale=${language}`}
            />
          )}
          {showCookiesConfiguration ? (
            <CookiesConfigurationModal
              onAccept={onAcceptCookies}
              onConfirm={value => {
                setCookiesConsent(value);
              }}
              cookiesPolicyLink={`${K.links.cookies_policy}?locale=${language}`}
            />
          ) : null}
        </>
      ) : (
        <>
          {cookiesConsentAnswered ? null : (
            <Cookies onAccept={onAcceptCookies} />
          )}
        </>
      )}
    </>
  );
}

export default AppRouter;
