import React from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { Paper, Typography, Label } from "../../../../atoms";
import { Bin } from "../../../../atoms/Icons";
import { CreateSelector } from "../../../../molecules";
import K from "../../../../constants";
import locales from "../../../../locales";

const useStyles = createUseStyles(theme => ({
  profile_selectors: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "32px",
    "@media (min-width: 768px)": {
      marginBottom: "40px"
    }
  },
  profile_selectors__title: {
    marginBottom: "8px"
  },
  profile_selectors__description: {
    marginBottom: "32px"
  },
  profile_selectors__items: {
    marginBottom: "16px"
  },
  profile_selectors__items__item: {
    display: "flex",
    justifyContent: "space-between",
    padding: "18px 0",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    "&:first-child": {
      paddingTop: "2px"
    }
  },
  profile_selectors__items__item__data: {
    margin: "8px 0",
    display: "flex",
    flexDirection: "column",
    "@media (min-width: 768px)": {
      flex: "50%"
    }
  },
  profile_selectors__items__item__action: {
    display: "flex",
    alignItems: "center"
  },
  profile_selectors__delete: {
    width: "20px",
    height: "20px",
    margin: "0 20px",
    border: "none",
    backgroundColor: "transparent"
  },
  profile_selectors__tooltip: {
    width: "160px!important",
    paddingLeft: "10px!important",
    paddingRight: "10px!important"
  },
  profile_selectors__items__item__text: {
    "& > span": {
      display: "inline-block",
      width: "180px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: "normal",
      "@media (min-width: 768px)": {
        width: "20vw",
        maxWidth: "280px"
      }
    }
  }
}));

const ProfileSelector = ({
  selectorType,
  selectorData,
  title,
  addSelectorText,
  createSelectorPlaceHolder,
  onSuccessAddedSelector,
  selectorTooltipText,
  onClickRemoveSelector
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const getSelectorStatus = (
    isValidated,
    validationExpired,
    validationStartedAt
  ) => {
    if (!isValidated) {
      if (validationStartedAt && !validationExpired) {
        return "isPending";
      }
      if (validationExpired && validationStartedAt) {
        return "noValid";
      }
    }
    return "isValidated";
  };

  const mappingProfileStatus = {
    isValidated: {
      text: intl.formatMessage(locales.profile_selector_validated),
      color: K.colors.success
    },
    isPending: {
      text: intl.formatMessage(locales.profile_selector_pending),
      color: K.colors.shade
    },
    noValid: {
      text: intl.formatMessage(locales.profile_selector_no_validated),
      color: K.colors.error
    }
  };

  return (
    <>
      <Typography
        variant={K.typographicVariants.heading4}
        className={classes.profile_selectors__title}
      >
        {title}
      </Typography>
      <Paper className={classes.profile_selectors}>
        <ul className={classes.profile_selectors__items}>
          {selectorData.map(selector => {
            const {
              id,
              isRemovable,
              isValidated,
              validationExpired,
              validationStartedAt
            } = selector;
            const selectorStatus = getSelectorStatus(
              isValidated,
              validationExpired,
              validationStartedAt
            );
            return (
              <li
                className={classes.profile_selectors__items__item}
                key={selector.id}
              >
                <div className={classes.profile_selectors__items__item__text}>
                  <Typography
                    variant={K.typographicVariants.body1}
                    color={theme.palette.primary.default}
                  >
                    {selector[selectorType]}
                  </Typography>
                </div>
                <div className={classes.profile_selectors__items__item__action}>
                  <Label
                    title={mappingProfileStatus[selectorStatus].text}
                    color={mappingProfileStatus[selectorStatus].color}
                  />
                  {isRemovable ? (
                    <button
                      type="button"
                      onClick={() => onClickRemoveSelector(id, selectorType)}
                      className={classes.profile_selectors__delete}
                    >
                      <Bin
                        width={20}
                        height={20}
                        color={theme.palette.tertiary.default}
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      data-tip
                      data-event="click"
                      data-for={`profile_selector_${selectorType}`}
                      className={classes.profile_selectors__delete}
                    >
                      <Bin
                        width={20}
                        height={20}
                        color={theme.palette.shade.default}
                      />
                    </button>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
        <CreateSelector
          addSelectorText={addSelectorText}
          createSelectorPlaceHolder={createSelectorPlaceHolder}
          selectorType={selectorType}
          onSuccessAddedSelector={onSuccessAddedSelector}
        />
      </Paper>
      <ReactTooltip
        id={`profile_selector_${selectorType}`}
        backgroundColor={theme.palette.secondary.default}
        effect="solid"
        globalEventOff="click"
        className={classes.profile_selectors__tooltip}
      >
        <Typography
          color={theme.palette.white.default}
          variant={K.typographicVariants.body2}
        >
          {selectorTooltipText}
        </Typography>
      </ReactTooltip>
    </>
  );
};

ProfileSelector.propTypes = {
  selectorData: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectorType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  addSelectorText: PropTypes.string.isRequired,
  createSelectorPlaceHolder: PropTypes.string.isRequired,
  onSuccessAddedSelector: PropTypes.func.isRequired,
  selectorTooltipText: PropTypes.string.isRequired,
  onClickRemoveSelector: PropTypes.func.isRequired
};

export default ProfileSelector;
