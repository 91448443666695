import React, { memo } from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import useSessionAuthenticationContext from "../../contexts/SessionAuthentication";
import useAppContext from "../../contexts/App";
import useShopperContext from "../../contexts/Shopper";
import useTrackingContext from "../../contexts/Tracking";
import { Greeting } from "../../molecules";
import { isMobile } from "../../utils";
import { ItemNavigation } from "../../atoms";
import {
  Bag,
  User,
  Faqs,
  Sequra,
  Logout,
  Dashboard,
  Invite,
  SveaPoweredBySequra
} from "../../atoms/Icons";
import locales from "../../locales";
import {
  DASHBOARD_PATH,
  HELP_PATH,
  ORDERS_PATH,
  PROFILE_PATH,
  REFERRAL_PROGRAM_PATH
} from "../../routes";

const useStyles = createUseStyles(theme => ({
  side_bar: {
    height: "100%",
    width: "240px",
    backgroundColor: theme.palette.white.default,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "16px 0 48px",
    "@media (min-width: 768px)": {
      padding: "32px 0 48px"
    }
  },
  side_bar__header: {
    padding: "0 16px"
  },
  side_bar__separator: {
    marginTop: "16px",
    marginBottom: "24px",
    backgroundColor: theme.palette.shade.default,
    width: "208px",
    height: "1px",
    marginRight: 0,
    border: 0
  },
  side_bar__navigation: {
    flex: 1
  },
  side_bar__footer: {
    "& > img": {
      paddingLeft: 30,
      paddingTop: 15
    },
    "& > svg": {
      marginLeft: 26
    }
  },
  side_bar__footer__logout: {
    marginTop: 15
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { logout, isFullSession } = useSessionAuthenticationContext();
  const { shopper } = useShopperContext();
  const { track, eventNames } = useTrackingContext();
  const {
    closeSidebar,
    openRequestLoginModal,
    redirectToLoginFromPartialSession
  } = useAppContext();

  const onClickItem = path => {
    closeSidebar();
    history.push(path);
  };

  return (
    <div className={classes.side_bar}>
      <div className={classes.side_bar__header}>
        <Greeting givenNames={shopper?.givenNames} />
      </div>
      <hr className={classes.side_bar__separator} />
      <div className={classes.side_bar__navigation}>
        <ItemNavigation
          active={Boolean(
            matchPath(pathname, { path: DASHBOARD_PATH, exact: true })
          )}
          name={intl.formatMessage(locales.general_navigation_dashboard)}
          icon={<Dashboard />}
          onClick={() => {
            onClickItem(DASHBOARD_PATH);
          }}
          onClickDisabled={openRequestLoginModal}
          disabled={!isFullSession}
        />

        <ItemNavigation
          active={Boolean(
            matchPath(pathname, { path: ORDERS_PATH, exact: true })
          )}
          name={intl.formatMessage(locales.general_navigation_orders)}
          icon={<Bag />}
          onClick={() => {
            onClickItem(ORDERS_PATH);
          }}
          onClickDisabled={openRequestLoginModal}
          disabled={!isFullSession}
        />
        {shopper.referralProgram.enabledToBeHost && (
          <ItemNavigation
            active={Boolean(
              matchPath(pathname, {
                path: REFERRAL_PROGRAM_PATH,
                exact: true
              })
            )}
            name={intl.formatMessage(
              locales.general_navigation_referral_program
            )}
            icon={<Invite />}
            onClick={() => {
              onClickItem(REFERRAL_PROGRAM_PATH);
              track(eventNames.referralProgram.userClicksSidebarLink);
            }}
            onClickDisabled={openRequestLoginModal}
            disabled={!isFullSession}
            isBeta
          />
        )}
        <ItemNavigation
          active={Boolean(matchPath(pathname, { path: PROFILE_PATH }))}
          name={intl.formatMessage(locales.general_navigation_profile)}
          icon={<User />}
          onClick={() => {
            onClickItem(PROFILE_PATH);
          }}
          onClickDisabled={openRequestLoginModal}
          disabled={!isFullSession}
        />
        <ItemNavigation
          active={Boolean(matchPath(pathname, { path: HELP_PATH }))}
          name={intl.formatMessage(locales.general_navigation_help)}
          icon={<Faqs />}
          onClick={() => {
            onClickItem(HELP_PATH);
          }}
        />
      </div>
      <div className={classes.side_bar__footer}>
        {shopper.realm === "svea" ? (
          <SveaPoweredBySequra width={180} height={40} />
        ) : (
          <Sequra
            width={isMobile.phone ? 97 : 124}
            height={isMobile.phone ? 20 : 32}
          />
        )}
        <div className={classes.side_bar__footer__logout}>
          <ItemNavigation
            name={intl.formatMessage(
              !isFullSession
                ? locales.general_navigation_login
                : locales.general_navigation_logout
            )}
            icon={<Logout />}
            onClick={() =>
              isFullSession
                ? logout()
                : redirectToLoginFromPartialSession("sidebar")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default memo(SideBar);
