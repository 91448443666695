import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useSpring, animated } from "react-spring";
import { useIntl } from "react-intl";
import { useTheme, createUseStyles } from "react-jss";
import { Typography } from "../../../../atoms";
import { ExpandMore } from "../../../../atoms/Icons";
import { useLocaleCurrency } from "../../../../hooks";
import locales from "../../../../locales";
import K from "../../../../constants";

const useStyles = createUseStyles(theme => ({
  summary_list: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "375px",
    borderBottom: `1px solid ${theme.palette.shade.default}`,
    marginBottom: "16px"
  },
  summary_list__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: ({ isOpen }) => (isOpen ? "8px" : 0),
    cursor: "pointer",
    "& > div": {
      display: "flex",
      alignItems: "center",
      "& > :first-child": {
        marginRight: "8px"
      },
      "& > :last-child": {
        display: "flex",
        transition: "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        transform: ({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(0)")
      }
    }
  },
  summary_list__entries: {
    display: "flex",
    flexDirection: "column",
    "& > div": {
      display: "flex",
      marginBottom: "8px",
      borderRadius: "8px",
      backgroundColor: theme.palette.background.default,
      padding: "3px 6px 5px",
      "& :first-child": {
        flex: 1
      },
      "& :last-child": {
        marginLeft: "16px"
      }
    }
  },
  summary_list__subheader: {
    marginTop: "8px",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between"
  }
}));

const InstalmentDropDown = ({ instalment }) => {
  const theme = useTheme();
  const intl = useIntl();
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles({ isOpen });

  const listStyles = useSpring({
    height: isOpen ? "auto" : 0,
    overflow: isOpen ? "hidden" : "auto"
  });

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const end = DateTime.fromISO(instalment.dueOn);
  const start = DateTime.now();
  const difference = Math.abs(Math.trunc(start.diff(end, "days").values.days));

  return (
    <div className={classes.summary_list}>
      <div className={classes.summary_list__header}>
        <div>
          <Typography
            variant={K.typographicVariants.body2}
            color={theme.palette.primary.default}
          >
            {intl.formatDate(instalment.dueOn, {
              year: "numeric",
              month: "short",
              day: "2-digit",
              timeZone: "Europe/Madrid"
            })}
          </Typography>
          <div
            onClick={toggleIsOpen}
            onKeyPress={toggleIsOpen}
            role="tab"
            tabIndex="0"
          >
            <ExpandMore color={theme.palette.primary.default} />
          </div>
        </div>
        <Typography
          variant={K.typographicVariants.body2}
          color={theme.palette.primary.default}
        >
          {useLocaleCurrency(instalment.unpaidAmount, {
            useCents: true
          })}
        </Typography>
      </div>
      <animated.div
        style={listStyles}
        className={classes.summary_list__entries}
        role="list"
      >
        {instalment.accountEvents.map(accountEvent => (
          <div key={accountEvent.id}>
            <Typography variant={K.typographicVariants.body2}>
              {intl.formatMessage(locales[accountEvent.type])}
            </Typography>
            <Typography variant={K.typographicVariants.body2}>
              {useLocaleCurrency(accountEvent.amount, { useCents: true })}
            </Typography>
          </div>
        ))}
      </animated.div>
      <div className={classes.summary_list__subheader}>
        <Typography
          variant={K.typographicVariants.caption1}
          color={
            instalment.isOverdue
              ? theme.palette.error.default
              : theme.palette.secondary.default
          }
        >
          {instalment.isOverdue
            ? intl.formatMessage(locales.order_overdue)
            : intl.formatMessage(locales.order_payment_pending_days, {
                quantity: difference
              })}
        </Typography>
      </div>
    </div>
  );
};

InstalmentDropDown.propTypes = {
  instalment: PropTypes.shape()
};

InstalmentDropDown.defaultProps = {
  instalment: {}
};

export default InstalmentDropDown;
