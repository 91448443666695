import React from "react";
import useShopperContext from "../../contexts/Shopper";
import DashBoardForShoppersWithOrders from "./DashboardForShoppersWithOrders";
import DashBoardForShoppersWithoutOrders from "./DashboardForShoppersWithoutOrders";
import { IntercomMessenger } from "../../components";

const Dashboard = () => {
  const { shopper } = useShopperContext();

  return (
    <>
      {shopper.activated || !shopper.referralProgram.featureFlagEnabled ? (
        <DashBoardForShoppersWithOrders />
      ) : (
        <DashBoardForShoppersWithoutOrders />
      )}
      <IntercomMessenger
        user_hash={shopper.intercomAuthenticationKey}
        user_id={shopper.id}
        email={shopper.primaryEmail}
        name={shopper.givenNames}
      />
    </>
  );
};

export default Dashboard;
