import * as Icons from "./components";

export const iconNames = {
  alert: "alert",
  americanExpress: "americanExpress",
  arrowDropDown: "arrowDropDown",
  arrowDropUp: "arrowDropUp",
  arrowLeft: "arrowLeft",
  arrowRight: "arrowRight",
  arrowFold: "arrowFold",
  arrowUnfold: "arrowUnfold",
  articles: "articles",
  bag: "bag",
  bin: "bin",
  calendar: "calendar",
  card: "card",
  cart: "cart",
  check: "check",
  checkBox: "checkBox",
  checkBoxOutlineBlank: "checkBoxOutlineBlank",
  checkCircle: "checkCircle",
  close: "close",
  creditCard: "creditCard",
  cursor: "cursor",
  dashboard: "dashboard",
  deliver: "deliver",
  envelop: "envelop",
  event: "event",
  expandLess: "expandLess",
  expandMore: "expandMore",
  faqs: "faqs",
  faqsBullet: "faqsBullet",
  flag: "flag",
  heart: "heart",
  heartBorder: "heartBorder",
  info: "info",
  invite: "invite",
  invoice: "invoice",
  location: "location",
  logout: "logout",
  masterCard: "masterCard",
  menu: "menu",
  navigationArrowBack: "navigationArrowBack",
  navigationArrowRight: "navigationArrowRight",
  noStore: "noStore",
  papyrus: "papyrus",
  pp3: "pp3",
  pp5: "pp5",
  pen: "pen",
  phone: "phone",
  radioButtonUnchecked: "radioButtonUnchecked",
  return: "return",
  sequra: "sequra",
  sp1: "sp1",
  shipping: "shipping",
  smileBag: "smileBag",
  star: "star",
  starBorder: "starBorder",
  store: "store",
  receipt: "receipt",
  tagPrice: "tagPrice",
  unicorn: "unicorn",
  user: "user",
  visa: "visa",
  peace: "peace",
  unicornFloat: "unicornFloat",
  fileCopy: "fileCopy",
  envelope: "envelope",
  whatsApp: "whatsApp",
  sveaPoweredBySequra: "sveaPoweredBySequra",
  cookie: "cookie",
  gift: "gift",
  transport: "transport",
  es: "es",
  pt: "pt",
  fr: "fr",
  it: "it"
};

export const iconsByName = {
  [iconNames.alert]: Icons.Alert,
  [iconNames.americanExpress]: Icons.AmericanExpress,
  [iconNames.arrowDropDown]: Icons.ArrowDropDown,
  [iconNames.arrowDropUp]: Icons.ArrowDropUp,
  [iconNames.arrowLeft]: Icons.ArrowLeft,
  [iconNames.arrowRight]: Icons.ArrowRight,
  [iconNames.arrowFold]: Icons.ArrowFold,
  [iconNames.arrowUnfold]: Icons.ArrowUnfold,
  [iconNames.articles]: Icons.Articles,
  [iconNames.bag]: Icons.Bag,
  [iconNames.bin]: Icons.Bin,
  [iconNames.calendar]: Icons.Calendar,
  [iconNames.card]: Icons.Card,
  [iconNames.cart]: Icons.Cart,
  [iconNames.check]: Icons.Check,
  [iconNames.checkBox]: Icons.CheckBox,
  [iconNames.checkBoxOutlineBlank]: Icons.CheckBoxOutlineBlank,
  [iconNames.checkCircle]: Icons.CheckCircle,
  [iconNames.close]: Icons.Close,
  [iconNames.creditCard]: Icons.CreditCard,
  [iconNames.cursor]: Icons.Cursor,
  [iconNames.dashboard]: Icons.Dashboard,
  [iconNames.deliver]: Icons.Deliver,
  [iconNames.envelop]: Icons.Envelop,
  [iconNames.event]: Icons.Event,
  [iconNames.expandLess]: Icons.ExpandLess,
  [iconNames.expandMore]: Icons.ExpandMore,
  [iconNames.faqs]: Icons.Faqs,
  [iconNames.faqsBullet]: Icons.FaqsBullet,
  [iconNames.flag]: Icons.Flag,
  [iconNames.star]: Icons.Heart,
  [iconNames.starBorder]: Icons.HeartBorder,
  [iconNames.info]: Icons.Info,
  [iconNames.invite]: Icons.Invite,
  [iconNames.invoice]: Icons.Invoice,
  [iconNames.location]: Icons.Location,
  [iconNames.logout]: Icons.Logout,
  [iconNames.masterCard]: Icons.MasterCard,
  [iconNames.menu]: Icons.Menu,
  [iconNames.navigationArrowBack]: Icons.NavigationArrowBack,
  [iconNames.navigationArrowRight]: Icons.NavigationArrowRight,
  [iconNames.noStore]: Icons.NoStore,
  [iconNames.papyrus]: Icons.Papyrus,
  [iconNames.pen]: Icons.Pen,
  [iconNames.phone]: Icons.Phone,
  [iconNames.pp3]: Icons.Pp3,
  [iconNames.pp5]: Icons.Pp5,
  [iconNames.radioButtonUnchecked]: Icons.RadioButtonUnchecked,
  [iconNames.return]: Icons.Return,
  [iconNames.sequra]: Icons.Sequra,
  [iconNames.sp1]: Icons.Sp1,
  [iconNames.shipping]: Icons.Shipping,
  [iconNames.smileBag]: Icons.SmileBag,
  [iconNames.star]: Icons.Star,
  [iconNames.starBorder]: Icons.StarBorder,
  [iconNames.store]: Icons.Store,
  [iconNames.receipt]: Icons.Receipt,
  [iconNames.tagPrice]: Icons.TagPrice,
  [iconNames.unicorn]: Icons.Unicorn,
  [iconNames.user]: Icons.User,
  [iconNames.visa]: Icons.Visa,
  [iconNames.peace]: Icons.Peace,
  [iconNames.unicornFloat]: Icons.UnicornFloat,
  [iconNames.fileCopy]: Icons.FileCopy,
  [iconNames.envelope]: Icons.Envelope,
  [iconNames.whatsApp]: Icons.WhatsApp,
  [iconNames.sveaPoweredBySequra]: Icons.SveaPoweredBySequra,
  [iconNames.cookie]: Icons.Cookie,
  [iconNames.transport]: Icons.Transport,
  [iconNames.gift]: Icons.Gift,
  [iconNames.es]: Icons.ES,
  [iconNames.pt]: Icons.PT,
  [iconNames.it]: Icons.IT,
  [iconNames.fr]: Icons.FR
};
